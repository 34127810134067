<script>
  export default {
    name: 'autocomplete',

    props: {
	  inputlabel:{
		type: String,
		default:'Pick',  
	  },
	  id:{
		type: String,
		required: true,  
	  },
	  inputname:{
		type: String,
		required: true,
	  },
	  inputvalue:{
		  type: String,
		  default:'',
	  },
	  api: {
		type: String,
		required: true,  
	  },

    },

    data() {
      return {
        results: [],
        search: '',
        isOpen: false,
      };
    },

    methods: {
        
	    fetch() {
            axios.get(this.api, { params: { keywords: this.search } })
                .then(response => this.results = response.data)
                .catch(function (error) { console.log(error); }
            );
        },
        
		setResult(val) {
			this.search = val;
			this.isOpen = false;
			this.$refs.keywords.focus();
			this.$emit('change', this.search);
		},
      
    },
    watch: {
      search: function (val, oldValue) {
        this.fetch();
        this.isOpen = true;
      },
    },
    mounted() {
	    this.search = this.inputvalue;
    },
    destroyed() {
    }
  };
</script>

<template>
	<div class="autocomplete" :id="id">
		<div class="input-group mb-3" data-toggle="tooltip" data-placement="top" title="Begin typing and matching values will appear below.">
			<div class="input-group-prepend">
				<span class="input-group-text" v-text=inputlabel></span>
			</div>
			<div class="dropdown">
				<input 
					type="text" 
					class="form-control mr-2 dropdown-toggle" 
					data-toggle="dropdown"
					:id=inputname 
					:name=inputname
					ref="keywords"  
					v-model="search"
					v-on:change.stop
				/>
				
				<div class="dropdown-menu" v-show="isOpen">
					<button
						class="dropdown-item" 
						type="button"
					    v-for="(result, i) in results"
					    :key="result.id"
					    @click="setResult(result.id)"
					>
					{{ result.id }}&nbsp;{{result.name}}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
  .autocomplete {
    position: relative;
  }

  .dropdown-menu.show {
  	display: block;
  }

</style>