<script>
	export default {
		props: {
			inputlabel:{
				type: String,
				default:'Pick',  
			},
			id:{
				type: String,
				required: true,  
			},
			inputname:{
				type: String,
				required: true,
			},
			inputvalue:{
				type: String,
				default:'Hello World',
			},
			info:{
				type: String,
				default:'',
			},
		},
		mounted(){
			this.scientificName.raw = this.inputvalue;
		},
		data() {
			return {
				scientificName:{
					raw: '',
					formatted: '',
				}
			 }
		},
		methods:{	
			format(val) {
				var patterns = [
					/(\(.*\))/,
					/(\'.*\')/,
					/(var\.)/,
					/(subsp\.)/,
					/(f\.)/, 
					/(species\/hybrid)/,
					/(species\/cultivar)/, 
					/(species)/, 
					/(cultivar)/, 
					/(hybrid)\b/, 
				];
				var replacement = '<span class="sci_plain"> $& </span>';
				var formatted = val;
				patterns.forEach( function(pattern){
					formatted = formatted.replace(pattern,replacement);
				});
				return(formatted);
			},				
		},
		watch: {
			'scientificName.raw': function(val,oldVal){
				this.scientificName.formatted = this.format(val);
				
			},	
		},
	}


</script>

<template>
<div class="scientific_formatter" :id="id">
	<div class="form-group col-md-6">
	    <label for="scientific_name" >{{inputlabel}}</label>
	      <input 
	      	type="text"maxlength="255" 
	      	class="form-control" 
			:id=inputname 
			:name=inputname
	      	v-model="scientificName.raw"
	      >
	    
	    <div class="alert alert-info" role="alert">
		  <span id="formatted_scientific_name" class="sci_italic" v-html="scientificName.formatted" ></span><br/>
		  For more information about formatting read the <a href="#" class="alert-link" data-toggle="modal" data-target="#formatGuideModal">formatting guide</a>.<br>
		  <span>{{ info }}</span>
		</div>
	    
	</div>

	<div class="modal" id="formatGuideModal" tabindex="-1" role="dialog">
	  <div class="modal-dialog" role="document">
	    <div class="modal-content">
	      <div class="modal-header">
	        <h5 class="modal-title">Formatting Guide</h5>
	        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
	      </div>
	      <div class="modal-body">
		    <p>By following some simple text conventions a scientific name will be formatted (italic and plain text) in subsequent publications where formatting is possible.
	        <p>The <i>Genus</i> name is written first and will be italicized.</p>
	        <p>The <i>Specific Epithet</i> (species) is written second and will be italicized.</p>
	        <p>Botanical abbreviations will be plain text while the text that follows will be italicized.</p>
	        <ul>
		        <li>f. <i>example</i></li>
		        <li>subsp. <i>example</i></li>
		        <li>syn. <i>example</i></li>
		        <li>var. <i>example</i></li>
	        </ul>
	        <p>The following keywords or phrases will be plain text</p>
	        <ul>
		        <li>hybrid</li>
		        <li>species</li>
		        <li>cultivar</li>
		        <li>species/hybrid</li>
		        <li>species/cultivar</li>
	        </ul>
	        <p>Text in single quotes or parentheses will be plain text.</p>
	      </div>
	      
	      <div class="modal-footer">
	        <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
	      </div>
	    </div>
	  </div>
	</div>
</div>

</template>


